import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import DefaultLayout from '../layout/defaultLayout';
import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from '.';
import { useContext } from 'react';
import { UserContext } from '../context/theme';
import DashLayout from '../layout/dashLayout';

const RoutesAll = (props) => {
    const {auth} = useContext(UserContext);
    return (
        <React.Fragment>
          <Routes>
            <Route>
              {publicProtectedFlattenRoutes.map((route, idx) => (
                <Route
                  path={route.path}
                  element={
                    <DefaultLayout {...props}>
                      {route.element}
                    </DefaultLayout>
                  }
                  key={idx}
                />
              ))}
            </Route>
    
            <Route>
              {authProtectedFlattenRoutes.map((route, idx) => (
                <Route
                  path={route.path}
                  element={
                    auth === false ? (
                      <Navigate
                        to={{
                          pathname: "/login",
                          search: "next=" + route.path
                        }}
                      />
                    ) : (
                      <DashLayout {...props}>{route.element}</DashLayout>
                    )
                  }
                  key={idx}
                />
              ))}
            </Route>
    
          </Routes>
        </React.Fragment>
      );
}

export default RoutesAll