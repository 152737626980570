import React from 'react'
import { Oval } from 'react-loader-spinner'

export default function Loader({isActive}) {
  return (
    <div className={`pageloaderWrp ${isActive?'active':''}`}>
      <div className="pageLoadr">
        <Oval color="#1160c1" height={70} width={70} />
      </div>
    </div>
  )
}
