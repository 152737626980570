// import logo from './logo.svg';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import "react-toastify/dist/ReactToastify.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "react-datepicker/dist/react-datepicker.css";

import "./App.css";

import React from "react";

import { UserContext, UserProvider } from "./context/theme";
import RoutesAll from "./route/routes";
import { ToastContainer } from "react-toastify";

function App() {
    return (
        <>
            <UserProvider>
                <UserContext.Consumer>{({ auth }) => (
					<>
						<ToastContainer />
						<RoutesAll />
						{/* <Loader isActive={true} />
						{auth === null && <Loader />}
						{auth === false && <Login />}
						{auth === true && <RoutesAll />} */}
					</>
				)}
				</UserContext.Consumer>
            </UserProvider>
        </>
    );
}

export default App;
